<template>
  <b-card
    v-if="products"
    no-body
    class="card-company-table"
  >
    <b-table
      :per-page="perPage"
      :current-page="currentPage"
      :items="products"
      responsive
      :fields="fields"
      class="mb-0"
      :busy="isBusy"
    >
      <!-- name -->
      <template #cell(name)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            rounded
            size="32"
            variant="light-company"
          >
            <b-img
              src="@/assets/images/icons/alerte.png"
              alt="avatar img"
            /></b-avatar>
          <div class="ml-1">
            <div class="font-weight-bolder">
              {{ data.item.name }}            </div>
            <div class="font-small-2 ">
              {{ data.item.reference }}            </div>
          </div>
        </div>
      </template>

      <!-- quantity -->
      <template #cell(quantity)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.quantity }}</span>
          <feather-icon
            icon="TrendingDownIcon"
            class="text-danger"
          />
        </div>
      </template>
      <!--loading-->
      <template #table-busy>
        <div class="text-center text-warning my-2">
          <b-spinner class="align-middle" />
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="products.length"
      :per-page="perPage"
      align="end"
      size="sm"
      class="my-1"
    />
  </b-card>
</template>

<script>
import {
  BCard, BTable, BAvatar, BImg, BSpinner, BPagination,
} from 'bootstrap-vue'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BSpinner,
    BPagination,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      products: [],
      isBusy: false,
      fields: [
        { key: 'name', label: 'produit' },
        { key: 'quantity', label: 'stock ' },
        { key: 'minimum_quantity', label: 'stock minimale ' },
      ],
    }
  },

  created() {
    this.getProducts()
  },
  methods: {
    async getProducts() {
      this.isBusy = true
      const { data } = await axiosIns.get('/products/alert/')
      this.products = data
      this.isBusy = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
